import { Button, Dropdown, Menu, Modal } from "antd";
import React from "react";
import { Component } from "react";
import LoadPointCloudModal from "../LoadPointCloudModal";
import PointCloudWindow from "../PCViewer/PointCloudWindow";
import {LoadablePointCloud} from "../types/LoadablePointCloud";
import LogoutButton from "../Logout";

interface State {

}

interface Props {
  LIDAR_VIEW: React.RefObject<PointCloudWindow>,
  loadPointCloud : (pc: LoadablePointCloud) => void
}

export default class Header extends Component<Props, State> {

  CHOOSE_POINT_CLOUD_MODAL : React.RefObject<LoadPointCloudModal>

    constructor(props: Props) {
        super(props);

        this.CHOOSE_POINT_CLOUD_MODAL = React.createRef();
    }

    componentDidMount() {


    }

    /**
     * @description Event that is called when we use our file drop down menu to make a selection.
     * @param option A menu select object
     */
    onFileMenuButtonSelected(option : any) {
      let selected = option.key as string;

      if(selected === "load_point_cloud") {
        // Show modal for selecting a point cloud
        this.CHOOSE_POINT_CLOUD_MODAL.current?.showModal();
      }
    }

    getFileMenu() {
        return (<Menu
        onClick={(e)=>this.onFileMenuButtonSelected(e)}
        items={[
          {
            key: 'load_point_cloud',
            label: "Load Point Cloud",
          },
        ]}
      />)
    }

    // Loads a point cloud by calling our load point cloud props function
    loadPointCloud = (pc : LoadablePointCloud) => {
      this.props.loadPointCloud(pc);
    }

    render() {
        return (
          <>
            <div style={{height:"100%", width:"100%", background:"#242424", padding:8}}>
            <div style={{float:"left"}}>
            <Dropdown overlay={this.getFileMenu()}>
            <Button size="small" type="text">File</Button>
       </Dropdown>
            </div>
            
            <div style={{marginLeft: 10, float:'right'}}>  <LogoutButton/></div>
       <div style={{height:"100%", width:75, float:"right", 
     backgroundImage:"url('./doppel/doppel full white.png')", backgroundSize:"cover", backgroundPosition:"center"}}/>
            </div>

            <LoadPointCloudModal ref={this.CHOOSE_POINT_CLOUD_MODAL} onLoad={this.loadPointCloud}/>
            </>
        )
    }
}