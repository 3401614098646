import AppManager from './AppManager';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'antd/dist/antd.dark.min.css';
import { useAuth0 } from "@auth0/auth0-react";
import CircleLoader from 'react-spinners/CircleLoader'
import LoginButton from './Login';
type User = {
    email?: string | null | undefined,
    nickname?: string | null | undefined,
    permissions?: Array<string>,
    email_verified?: boolean | null | undefined,
}

interface Props {
    // Whether to include the nav bar and sidebar
    noPageLayout?: boolean;
    // User info
    user?: any;
    // Children to wrap in layout component
    children?: any;
    // Whether to remove the sidebar (just show nav bar)
    noSidebar?: boolean;
    // If page requires permissions, pass it in here so it can be properly handled
    permissionRequired?: string;
    // The title of the page we are on
    pageTitle?: string;
}
function App() {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    


    if (isLoading) {

        return    <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
             <div  style={{ position: "relative" }}>
                <CircleLoader
                    size={150}
                    color={ "#16b5b5"}
                    loading={true}
                />
                <div className='ctn-loadingcircle-body' style={{ color:  "#16b5b5", textAlign: "center", fontSize: 34,  }}>
                </div>
            </div>
            </div>;
    }
    else {

        if (!isAuthenticated) {
            // center the login button
            return (
                <div style={{ height: '100vh', width:'100vw', display:'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center' }}>
                    <h1>Welcome to Citian</h1>
                    <h2>Please log in to continue</h2>
                    <div><LoginButton/></div>
                
                </div>
            );
        } else {
            return (
                <div>
                    <div id="event_listener" />
                    <AppManager getAccessTokenSilently={getAccessTokenSilently} />
                </div>
            );
        }
    }
    
}

export default App;
