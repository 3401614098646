/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import MapView from './Map/MapView';
import OrientationDataset from './utils/OrientationDataset';
import Header from './UI/Header';
import PointCloudWindow from './PCViewer/PointCloudWindow';
import { LoadablePointCloud } from './types/LoadablePointCloud';

import './AppManager.css';
import { LngLat } from 'mapbox-gl';

interface State {
    showMap: boolean;
    loadedPointCloud: LoadablePointCloud;
    leftPanelWith: number;
}

interface Props {
    getAccessTokenSilently: (options?: any) => Promise<string>;
}

export default class AppManager extends Component<Props, State> {
    HEADER_HEIGHT = 40;

    orientationDataset?: OrientationDataset;

    LIDAR_VIEW: React.RefObject<PointCloudWindow>;
    MAP_VIEW: React.RefObject<MapView>;

    constructor(props: Props) {
        super(props);

        this.state = {
            showMap: true,
            leftPanelWith: 65,
            loadedPointCloud:      {
                url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/all_lidar`,
                name: "LA Pilot All Data",
                description: "Scan of all sample LA ",
                date_uploaded: new Date(`9/26/2024`),
                data_identifier: 'la_pilot',
                collector: 'NV5',
                projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

            },
        };

        this.LIDAR_VIEW = React.createRef();
        this.MAP_VIEW = React.createRef();

        this.handleOnMouse = this.handleOnMouse.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        // Load our map from mapbox
        this.MAP_VIEW.current?.loadMap(async () => {
            // When mapbox is finished initializing, load our point cloud.
            await this.loadPointCloud();

        });

        // Flush and lingering event listeners and setup new ones.
        this.flushAndSetupEventListeners();

        document.addEventListener(
            'mouseup',
            () => {
                document.removeEventListener('mousemove', this.onResize);
            },
            false
        );
    }

    async componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>
    ): Promise<void> {}

    loadPointCloudFromLoadablePointCloud = (pc: LoadablePointCloud) => {
        this.setState({ loadedPointCloud: pc });
        this.componentDidMount();
    };

    /**
     * @description Flushes all our event listeners and adds them back so we don't have duplicate listeners.
     */
    flushAndSetupEventListeners() {
        // Grab our event listener div in the root.
        let eventListener = document.getElementById('event_listener');

        // Check if we have it
        if (eventListener) {
            // Flush Procedure time:

            // Clone the element
            const newListener = eventListener.cloneNode(true);
            // Replace it with the clone, removing all listeners
            eventListener.replaceWith(newListener);
            // Set our new lister as our event listener
            eventListener = newListener as HTMLElement;

            // Begin adding our events:

            eventListener.addEventListener('on-free-cam-moved', (e) => {
                // transform position and rotation to mapbox coordinates
                
                // Our custom even has a payload that TS isn't recognizing
                // @ts-ignore e.detail
                // @ts-ignore e.detail
                const position_override = e.detail.position;
                // @ts-ignore e.detail
                const rotation_override = e.detail.rotation;
                // @ts-ignore e.detail
                const cameraRotation = rotation_override;
                const cameraPosition = position_override;

                if (!cameraRotation || !cameraPosition) {
                    console.error(
                        'Failed to update camera rotation on mapbox map'
                    );
                    return;
                }
                let positiveAngle = cameraRotation.z * (180 / Math.PI);
                positiveAngle = positiveAngle % 360;
                positiveAngle =
                    positiveAngle > 0 ? positiveAngle : positiveAngle + 360;
                // Make sure our dataset has been initialized or we don't care
                if (this.orientationDataset) {          
                    this.MAP_VIEW.current?.updateUserLocation(
                        cameraPosition
                        ,
                        360 - positiveAngle
                    );
                }
            
            });
        }
    }

    async loadPointCloud() {
        // Wait until we finished loading our point cloud
        await this.LIDAR_VIEW.current?.loadPointCloud(
            this.state.loadedPointCloud.url
        );
        // Create a new orientation dataset object, this will store all our information needed for stitching photos to lidar
        const orientationDataset = new OrientationDataset({
            url: `${this.state.loadedPointCloud.url}/orientation.csv`,
            projection: this.state.loadedPointCloud.projection,
            collector: this.state.loadedPointCloud.collector,
        });
        // Store this dataset on our AppManager object
        this.orientationDataset = orientationDataset;
        // Load our dataset data in
        await orientationDataset.loadDataset();
        // console.log('the orientation data set', orientationDataset)
        // Load our photospheres if such a dataset exists
        if (orientationDataset.successfullyLoaded) {
            // Make sure our map is showing
            this.setState({ showMap: true }, () =>
                window.dispatchEvent(new Event('resize'))
            );
            // Add photospheres to lidar
            this.LIDAR_VIEW.current?.addPhotoSpheres(
                orientationDataset.getLocations()
            );

            setTimeout(() => {
                // Update our map with our new photospheres
                this.MAP_VIEW.current?.updatePhotoSpheres(
                    orientationDataset.getLngLat()
                );
            }, 1000);
            // Add photospheres to our mapbox map
            this.MAP_VIEW.current?.updatePhotoSpheres(orientationDataset.getLngLat());
        }
        // Start out map at the first photosphere

        if (orientationDataset.photoSpheres.length > 0) {
            if (this.state.loadedPointCloud.url ===  `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/minessota`) {
                this.MAP_VIEW.current?.teleportTo(
                    15,
                    new LngLat( -93.23981743470034, 44.98457493637079,)
                
                );
            }else if (this.state.loadedPointCloud.name === 'LA Pilot PA_06') {
                this.MAP_VIEW.current?.teleportTo(
                    14,
                    // TODO: fix this hard coding
                    // @ts-ignore - hard coding this for now since la pilot doesnt have all the lidar data
                    orientationDataset.projectWorldToLngLat(
                        orientationDataset.photoSpheres[5482].camera_pos
                    )
                );
            }else if (this.state.loadedPointCloud.name === 'LA Pilot PA_09') {
                this.MAP_VIEW.current?.teleportTo(
                    14,
                    // TODO: fix this hard coding
                    // @ts-ignore - hard coding this for now since la pilot doesnt have all the lidar data
                    orientationDataset.projectWorldToLngLat(
                        orientationDataset.photoSpheres[904].camera_pos
                    )
                );
            }else if (this.state.loadedPointCloud.name === 'LA Pilot PA-00005, PA-00001, and PA-00010') {
                this.MAP_VIEW.current?.teleportTo(
                    15,
                    // TODO: fix this hard coding
                    // @ts-ignore - hard coding this for now since la pilot doesnt have all the lidar data
                    orientationDataset.projectWorldToLngLat(
                        orientationDataset.photoSpheres[1000].camera_pos
                    )
                );
            }else if (this.state.loadedPointCloud.name === 'LA Pilot PA_20') {
                this.MAP_VIEW.current?.teleportTo(
                    14,
                    // TODO: fix this hard coding
                    // @ts-ignore - hard coding this for now since la pilot doesnt have all the lidar data
                    orientationDataset.projectWorldToLngLat(
                        orientationDataset.photoSpheres[314].camera_pos
                    )
                );
            }
            else {
                this.MAP_VIEW.current?.teleportTo(
                    18,
                    orientationDataset.projectWorldToLngLat(
                        orientationDataset.photoSpheres[0].camera_pos
                    )
                );
            }
        }
        // else if our lidar view even exists...
        else if (this.LIDAR_VIEW.current) {
            this.MAP_VIEW.current?.teleportTo(
                12,
                orientationDataset.projectWorldToLngLat(
                    this.LIDAR_VIEW.current.getPointCloudCenter()
                )
            );
        }
    }

    /**
     * @description Takes an index and changes our camera to view that photosphere including the panoramic skybox and everything.
     * @param index The index of our photosphere we want to set ourselves to view
     * @return {void}
     */
    viewPhotoSphere = (index: number) => {
        if (!this.orientationDataset)
            return console.error(
                'Failed to load photosphere, no orientation dataset loaded!'
            );
        if (index >= this.orientationDataset.locations.length || index < 0)
            return console.error(
                'Failed to load photosphere, index out of bounds!'
            );

        console.debug(
            `Jumping to photosphere ${index} at`,
            this.orientationDataset.locations[index]
        );

        this.LIDAR_VIEW.current?.jumpToPhotoSphere(
            this.orientationDataset.photoSpheres[index]
        );
    };

    onResize(e: any) {
        let newWidth = Math.round((e.x / document.body.clientWidth) * 10000) / 100.0

        // max width
        if( newWidth > 99.75 ) {
            newWidth = 99.75
        }

        // min width
        if( newWidth < 0.25) {
            newWidth = 0.25
        }

        this.setState(
            {
                leftPanelWith: newWidth,
            },
            
            // re-draw canvas
            () => window.dispatchEvent(new Event('resize'))
        );
    }

    handleOnMouse(e: any) {
        switch (e.type) {
        case 'mousedown':
            document.addEventListener('mousemove', this.onResize);
            break;
        default:
        }
    }

    render() {
        return (
            <div>
                <div
                    style={{
                        zIndex: 10,
                        position: 'absolute',
                        top: 0,
                        height: this.HEADER_HEIGHT,
                        width: '100%',
                    }}
                >
                    <Header
                        loadPointCloud={
                            this.loadPointCloudFromLoadablePointCloud
                        }
                        LIDAR_VIEW={this.LIDAR_VIEW}
                    />
                </div>

                <div
                    id="left_panel"
                    style={{
                        width: this.state.showMap
                            ? this.state.leftPanelWith + '%'
                            : '100%',
                        position: 'absolute',
                        top: this.HEADER_HEIGHT,
                        bottom: 0,
                    }}
                >
                    <PointCloudWindow
                        ref={this.LIDAR_VIEW}
                        mapRef={this.MAP_VIEW}
                    />
                </div>

                <div
                    style={{
                        display: this.state.showMap ? '' : 'none',
                        position: 'absolute',
                        width: 100 - this.state.leftPanelWith + '%',
                        background: 'black',
                        right: 0,
                        top: this.HEADER_HEIGHT,
                        bottom: 0,
                    }}
                >
                    <div
                        id="resizer"
                        onMouseDown={this.handleOnMouse}
                    ></div>
                    <MapView
                        getAccessTokenSilently={
                            this.props.getAccessTokenSilently
                        }
                        loadedPointCloud={this.state.loadedPointCloud}
                        ref={this.MAP_VIEW}
                        LIDAR_VIEW={this.LIDAR_VIEW}
                        viewPhotoSphere={this.viewPhotoSphere}
                    />
                </div>
            </div>
        );
    }
}
