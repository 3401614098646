import { Component } from "react";
import mapboxgl, { LngLat } from 'mapbox-gl';
import PointCloudWindow from "../PCViewer/PointCloudWindow";
import { LoadablePointCloud } from "../types/LoadablePointCloud";
import { Button, Image, Tooltip } from "antd";
import ReactDOM from "react-dom";
import { CircleLoader } from "react-spinners";
import { Euler, Vector3 } from "three";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import proj4 from "proj4";

interface State {
    map?: mapboxgl.Map;
    loadingImage: boolean;
    imageURL: string;
    currentLocation: { lat: number, lng: number }}


interface Props {
    LIDAR_VIEW: React.RefObject<PointCloudWindow>,
    loadedPointCloud:LoadablePointCloud,
    getAccessTokenSilently: (options?: any) => Promise<string>,
    viewPhotoSphere: (index: number) => any;

}

export default class MapView extends Component<Props, State> {
    mapContainer: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            map: undefined,
            loadingImage: false,
            imageURL: '',
            currentLocation: { lat: 0, lng: 0 },

        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        // update current location of user
        if (prevState.currentLocation !== this.state.currentLocation) {

        }
    }
    // Generate a coordinate feature used for geocoding
    coordinateFeature = (lng: number, lat: number, place_name?: string) => {
        return {
            center: [lng, lat],
            geometry: {
                type: 'Point',
                coordinates: [lng, lat],
            },
            place_name: place_name || 'Lat: ' + lat + ' Lng: ' + lng,
            place_type: ['coordinate'],
            properties: {
            },
            type: 'Feature',
        };
    };

    // Custom Geocoder for search bar includes coordinate matching
    customGeocoder = (query: string): any[] | undefined => {
        // Match anything which looks like
        // decimal degrees coordinate pair.
        const matches = query.match(
            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
        );


        // Start asset ID matching if query does not match coordinate pair
        if (!matches) {
            return;
        }

        // @ts-ignore Doesn't like how matches can be null due to the if statement above
        const coord1 = Number(matches[1]);
        // @ts-ignore
        const coord2 = Number(matches[2]);
        const geocodes = [];

        if (coord1 < -90 || coord1 > 90) {
            // must be lng, lat
            geocodes.push(this.coordinateFeature(coord1, coord2));
        }

        if (coord2 < -90 || coord2 > 90) {
            // must be lat, lng
            geocodes.push(this.coordinateFeature(coord2, coord1));
        }

        if (geocodes.length === 0) {
            // else could be either lng, lat or lat, lng
            geocodes.push(this.coordinateFeature(coord2, coord1));
            geocodes.push(this.coordinateFeature(coord1, coord2));
        }
        return geocodes;
    };

    setupGeolocator(map: mapboxgl.Map, geocoderObject: MapboxGeocoder, position: "top-right" | "top-left" | "bottom-right" | "bottom-left" | undefined) {
        // Setup the geocoder for searching addresses
        map.addControl(
            geocoderObject, position,
        );
    }

    loadMap(callback: Function) {
        mapboxgl.accessToken = "pk.eyJ1Ijoic2piLWNpdGlhbiIsImEiOiJja2UwODV4NXgycnllMzFscTF4NWd5MGxpIn0.6q_ifivfIOc5WbstDSp-aA";

        const map = new mapboxgl.Map({
            container: this.mapContainer, // container ID
            style: 'mapbox://styles/sjb-citian/cke08b92b16iu19pjqbesnnnw', // style URL
            center: [-77, 44],
            minZoom: 1,
            maxZoom: 21,
            zoom: 18, // starting zoom
        });

        this.setState({ map });


        map.on('load', () => {
            // Setup map layers
            this.setupLayers();
            // @ts-ignore mapbox is weird i hate
            this.setupGeolocator(map, new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                // @ts-ignore because localGeocoder wants customGeocoder to return Results[], not potentially undefined
                localGeocoder: this.customGeocoder,
                placeholder: "Address or Coordinates",
                // @ts-ignore because for some reason it doesnt recognize mapboxgl as the proper value for the mapboxgl property
                mapboxgl,
                autocomplete: true,
            }), 'bottom-right');

            // Callback that our map has completed initialization
            callback();
        });
    }

    setupLayers() {
        // Add our photo sphere data source
        this.state.map?.addSource('photo-spheres', {
            type: 'geojson',
            data: { 'type': 'FeatureCollection', 'features': [] },
        });

        this.state.map?.addSource('user-camera', {
            type: 'geojson',
            data: { 'type': 'FeatureCollection', 'features': [] },
        });
        // Set our layer style photo spheres
        this.state.map?.addLayer({
            "id": "photo-spheres-layer",
            "source": "photo-spheres",
            "type": "circle",
            'paint': {
                'circle-color': '#ffff00',
                'circle-stroke-color': '#000000',
                'circle-stroke-width': 1,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [2, 2.5],
                        [22, 22],
                    ],
                },
            },
        });

        this.state.map?.loadImage(
            './user-map-icon.png',
            (error, image) => {
                if (error) throw error;

                // @ts-ignore Add the image to the map style.
                this.state.map?.addImage('user-camera-icon', image);

                // Set our layer style user camera
                this.state.map?.addLayer({
                    "id": "user-camera-layer",
                    "source": "user-camera",
                    "type": "symbol",
                    'layout': {
                        'icon-image': 'user-camera-icon', // reference the image
                        'icon-size': 0.25,
                        'icon-rotate': ['get', 'azimuth'],
                        'icon-rotation-alignment': 'map',
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                    },
                });
            });


        this.state.map?.removeLayer('satellite');

        if (this.props.loadedPointCloud.data_identifier !== 'minessota') {
            this.state.map?.addSource('esri', {
                type: "raster",
                tiles: [`https://citian-public-v2.s3.us-east-2.amazonaws.com/orthophotos/helena_extraction/{z}/{x}/{y}.png`],
                tileSize: 256,
            });
        } else {
            // use open street maps as tiles
            this.state.map?.addSource('esri', {
                type: "raster",
                tiles: ['https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],
                tileSize: 256,
            });
        }

        this.state.map?.addLayer({
            'id': 'satellite',
            'type': 'raster',
            'source': 'esri',
            'minzoom': 1,
            'maxzoom': 18,
        }, 'road-label');


        // this.state.map?.setLayoutProperty("satellite", "visibility", "none");

        this.setupLayerEvents();
    }

    _arrayBufferToBase64 = (buffer : Uint8Array) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    addPopup(jsx: any, lat: number, lng: number, map: mapboxgl.Map, class_name?: string, onClose?: (e: any) => void) {
        const placeholder = document.createElement('div');
        ReactDOM.render(jsx, placeholder);
        const popup = new mapboxgl.Popup({
            className: class_name,
        })
            .setDOMContent(placeholder)
            .setLngLat({ lng: lng, lat: lat })
            .addTo(map);

        popup.on('close', (e) => {
            if (onClose) {
                onClose(e);
            }
        });

        map.on('closeAllPopups', () => {
            popup.remove();
        });
    }
    setupLayerEvents() {
        if (!this.state.map) return;

        const map = this.state.map;

        this.state.map?.on('mousemove', 'photo-spheres-layer', (event) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        this.state.map?.on('mouseleave', 'photo-spheres-layer', (event) => {
            map.getCanvas().style.cursor = '';
        });

        this.state.map?.on('click', 'photo-spheres-layer', (event) => {
            if (event.features && event.features.length > 0) {
                const selected_feature = event.features[0];
                if (selected_feature.properties) {
                    this.props.viewPhotoSphere(selected_feature.properties.index);
                }
            }
        });
    }


    updateUserLocation(location: any, azimuth: number) {
        const new_proj = proj4(this.props.loadedPointCloud.collector === 'NV5' ?  '+proj=utm +zone=15 +ellps=GRS80 +datum=NAD83 +units=m +no_defs' : '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs','WGS84', [location.x, location.y])
        const source = this.state.map?.getSource('user-camera') as mapboxgl.GeoJSONSource;
        this.setState({ currentLocation: { lat: new_proj[0], lng: new_proj[1] } });
        // Set our photo spheres into the map
        source.setData(
            {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        // @ts-ignore`
                        properties: { azimuth, lat: new_proj[0], lng: new_proj[1] },
                        geometry: {
                            type: "Point",
                            coordinates: [
                                // @ts-ignore
                                new_proj[0],  new_proj[1],
                            ],
                        },
                    }],
            },
        );
    }

    updatePhotoSpheres(photoSphereLngLat: LngLat[]) {
        const source = this.state.map?.getSource('photo-spheres') as mapboxgl.GeoJSONSource;
        // Begin our indexing at -1 so we can increment first in our map making the first entry 0.
        let index = -1;
        // Set our photo spheres into the map
        source.setData(
            {
                type: "FeatureCollection",
                features:
                    photoSphereLngLat.map((pos) => {
                        index++;
                        return ({
                            type: "Feature",
                            properties: { index, lat: pos.lat, lng: pos.lng },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    pos.lng, pos.lat,
                                ],
                            },
                        });
                    },
                    ),


            },
        );
    }

    teleportTo(zoom: number, location: LngLat) {
        this.state.map?.panTo(location, { duration: 0 });
        this.state.map?.zoomTo(zoom);
    }

    flyTo(zoom: number, location: LngLat) {
        this.state.map?.flyTo({
            center: location,
            zoom,
        });
    }

    render() {
        return (
            <>
                <div id="map-container" ref={(el) => this.mapContainer = el} style={{ width: "100%", height: "100%" }} />
                <Tooltip title={"Click to copy the full location"}>
                    <div style={{
                        position: "absolute",
                        top: '5%',
                        right: '10%',
                        // set background color to opaque
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        color: 'white',
                        width: 160,
                        height: 100,
                        overflow: 'clip',
                        padding: 6,
                        zIndex: 9999999,
                        cursor: "pointer",
                        // make sure text stays in one line
                        whiteSpace: 'nowrap',
                    }}
                    id="customDiv" onClick={() => {
                    // copy the current location to the clipboard
                        navigator.clipboard.writeText(`Lat: ${this.state.currentLocation.lat} Lng: ${this.state.currentLocation.lng}`);
                    }}>
                        Click to copy location <br/>
                        Latitude:{this.state.currentLocation.lat}<br/>
                        Longitude: {this.state.currentLocation.lng}
                        <br/>

                    </div>
                </Tooltip>

            </>
        );
    }
}
