
import { Vector3 } from "three";
import { LngLat } from "mapbox-gl";
import proj4 from "proj4";
import PhotoSphere from "./PhotoSphere";

 interface OrientationDatasetConstructor {
    url: string,
    projection?:string,
    datum?: string
    collector: string
 }


export default class OrientationDataset {
    url: string;
    projection : string;
    datum : string;
    collector: string;

    // Protected
    locations: Vector3[];
    lnglat: LngLat[];
    photoSpheres: PhotoSphere[];

    successfullyLoaded = false;

    constructor(params : OrientationDatasetConstructor) {
        // URL to the CSV file
        this.url = params.url;
        this.collector = params.collector;
        // The projection the lidar data was taken
        this.projection = params.projection ? params.projection : "";
        this.datum = params.datum || "NAD83";

        this.locations = [];
        this.lnglat = [];
        this.photoSpheres = [];
    }

    projectWorldToLngLat(coords : Vector3) {
        try {
            const new_proj = proj4(
                this.projection,
                "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs", // Mapbox projection
                coords,
            );
            return new LngLat(new_proj.x, new_proj.y);
        } catch {
            console.error("Failed to project point:", coords);
        }

        return new LngLat(0, 0);
    }

    async fetchAndProcessCSV(url:string) {
        const response = await fetch(url);
        if (response.body) {
            const reader = response.body.getReader();

            const decoder = new TextDecoder('utf-8');

            let partialLine = '';
            const csvData = []; // Array to store the parsed data

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }

                const strChunk = decoder.decode(value, { stream: true });
                const lines = (partialLine + strChunk).split('\n');
                partialLine = lines.pop() as string; // Last line might be incomplete, save it for the next chunk

                lines.forEach((line) => {
                    const values = this.processLine(line);
                    if (values) {
                        csvData.push(values); // Add the parsed line to the array
                    }
                });
            }

            if (partialLine) {
                const values = this.processLine(partialLine); // Process the last partial line
                if (values) {
                    csvData.push(values);
                }
            }
            return csvData; // Return the array containing the parsed CSV data
        }
    }

    processLine(line:string) {
        // Splitting by semicolon and filtering out empty lines 
        return this.collector === 'NV5' ? line.split(',') : line.split(';');
    }

    /**
     * Load a specific dataset (point cloud, external camera orientation, etc)
     * into the AppManager. Once the dependency data has been loaded, the map
     * and three.js viewer can be properly initialized using the data.
     */
    async loadDataset() {
        this.successfullyLoaded = false;
        const response = await fetch(this.url);

        await this.fetchAndProcessCSV(this.url).then((data) => {
            if (data) {
                for (let i = 1; i < data.length; i++) {
                    
                    const newData = data[i];
                    const position =   new Vector3(parseFloat(newData[2]), parseFloat(newData[3]), parseFloat(newData[4]))

                    const new_proj = proj4(
                        this.projection,
                        "WGS84", // Mapbox projection
                        new Vector3(parseFloat(newData[2]), parseFloat(newData[3]), parseFloat(newData[4]))
                    );
                    this.locations.push(position);
                    this.lnglat.push(new LngLat(new_proj.x, new_proj.y));
                    // if the url contains 1300 Billings
                    const trackName = newData[0].split('-').slice(0, 2).join('');
                    // const image_url = this.url.includes('1300Billings') ? this.url.slice(0, this.url.lastIndexOf("/"))+`/panoramas/${newData[0]}` : this.url.slice(0, this.url.lastIndexOf("/"))+`/panoramas/${trackName}/${newData[0]}`;
                    const image_url = this.collector === 'NV5' ? this.url.slice(0, this.url.lastIndexOf("/"))+`/panoramas/${newData[1]}` : this.url.slice(0, this.url.lastIndexOf("/"))+`/panoramas/${trackName}/${newData[0]}`;    
                    if (image_url === "https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/panoramas/240922_213711693.jpg") {
                        console.log(' the image url is ', i);
                    }
                    if (this.collector === 'NV5') {
                        this.photoSpheres.push(
                            
                            new PhotoSphere(
                                image_url,
                                position,
                                new Vector3(parseFloat(newData[2])  - parseFloat(newData[5]) , parseFloat(newData[3]) , parseFloat(newData[4]) ),
                                new Vector3(90,  -180 , 0 ),  
                            ),
                        );
                    } else {
                        this.photoSpheres.push(
                            new PhotoSphere(
                                image_url,
                                position,
                                new Vector3(parseFloat(newData[7]) , parseFloat(newData[8]) , parseFloat(newData[9]) ),
                                new Vector3(parseFloat(newData[10]) , parseFloat(newData[11]) , parseFloat(newData[12]) ), 
                            ),
                        );
                    }
                  
                }
            }
            this.successfullyLoaded = true;
        },
        ).catch((error) => {
            console.error('Error:', error);
        },
        );
        if (!response.ok) {
            // We failed to find an orientation dataset.
            console.debug("Failed to find an orientation.csv, not using the map.");
            return;
        }


        console.debug(`Successfully loaded orientation dataset. Found ${this.locations.length} valid camera points.`);
    }

    getLocations() {
        return this.locations;
    }

    getLngLat() {
        return this.lnglat;
    }
}
