import { Vector3 } from "three"

export default class PhotoSphere {

    /** The file URL to our panorama image */
    panorama_image: string
    /** Our coordinates in our lidar space */
    camera_pos: Vector3
    /** Our camera target position */
    camera_target: Vector3
    /** Our initial rotation (omega, phi, kappa) */
    initial_rotation: Vector3
  

    constructor(panorama_image : string, camera_pos : Vector3, camera_target: Vector3, initial_rotation: Vector3) {
        this.panorama_image = panorama_image;
        this.camera_pos = camera_pos;
        this.camera_target = camera_target;
        this.initial_rotation = initial_rotation;
    }


}