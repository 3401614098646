/**
 * @file skybox.ts
 *
 * @description The skybox class controls the background
 * panormaic sky. It extends WebGLCubeRenderTarget for allowing
 * rotations of the sky to match up with the 360 panoramic image.
 *
 * This class is what lines up the background panorama to the point cloud at a
 * specific location, specifically the camera rotation/orientation.
 *
 * @author Lucas Govatos <lucas@citiansolutions.com>
 *
 * @date 9/3/2021
 */


import {
    WebGLCubeRenderTarget,
    RGBAFormat,
    BoxGeometry,
    ShaderMaterial,
    Mesh,
    CubeCamera,
    LinearMipmapLinearFilter,
    BackSide,
    NoBlending,
    LinearFilter,
    Scene,
    WebGLRenderer,
    Texture,
    Vector3,
} from "three";
import { cloneUniforms } from 'three/src/renderers/shaders/UniformsUtils';


class Skybox extends WebGLCubeRenderTarget {
    public shader = {

        uniforms: {
            tEquirect: { value: null },
            prev_tEquirect: { value: null },
            alpha: { value: 0.0 },
        },

        vertexShader: /* glsl */`
			varying vec3 vWorldDirection;
			vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
				return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
			}
			void main() {
				vWorldDirection = transformDirection( position, modelMatrix );
				#include <begin_vertex>
				#include <project_vertex>
			}
		`,

        fragmentShader: /* glsl */`
			uniform sampler2D tEquirect;
			uniform sampler2D prev_tEquirect;
			uniform float alpha;
			varying vec3 vWorldDirection;

			#include <common>
			void main() {
				vec3 direction = normalize( vWorldDirection );
				vec2 sampleUV = equirectUv( direction );
				vec4 _texture = texture2D(prev_tEquirect, sampleUV);
          		vec4 _texture2 = texture2D(tEquirect, sampleUV);
				gl_FragColor = mix(_texture, _texture2, alpha);
			}
		`,
    };

    public currentTexture: Texture | undefined;
    public prevTexture: Texture | undefined;

    public material = new ShaderMaterial( {
        name: 'CubemapFromEquirect',
        uniforms: cloneUniforms( this.shader.uniforms ),
        vertexShader: this.shader.vertexShader,
        fragmentShader: this.shader.fragmentShader,
        side: BackSide,
        blending: NoBlending,
    } );

    public geometry = new BoxGeometry( 10, 10, 10 );
    public mesh = new Mesh(this.geometry, this.material);
    public camera = new CubeCamera( 1, 10, this );

    /**
	 * Creates an equirectangular texture, rotated by specified angles.
	 * @param renderer
	 * @param texture
	 * @param rotation
	 * @returns
	 */
    async fromEquirectangularTextureWithAngle(
        renderer: WebGLRenderer,
        texture: Texture,
        rotation: Vector3) {
        this.texture.type = texture.type;
        this.texture.format = RGBAFormat; // see #18859
        this.texture.encoding = texture.encoding;

        this.texture.generateMipmaps = texture.generateMipmaps;
        this.texture.minFilter = texture.minFilter;
        this.texture.magFilter = texture.magFilter;
        if (!this.prevTexture) {
            this.currentTexture = texture;
        }

        this.prevTexture = this.currentTexture;
        this.currentTexture = texture;

        this.material.uniforms.tEquirect.value = this.currentTexture;
        this.material.uniforms.prev_tEquirect.value = this.prevTexture;
        this.material.uniforms.alpha.value = 0.0;

        this.material.needsUpdate = true;

        const currentMinFilter = texture.minFilter;

        // Avoid blurred poles
        if ( texture.minFilter === LinearMipmapLinearFilter ) texture.minFilter = LinearFilter;

        // Setup camera orientation based on the supplied rotation and angles.
        this.camera.rotation.x = 0;
        this.camera.rotation.y = 0;
        this.camera.rotation.z = 0;
        this.camera.up.set(0, 0, 1);

        // Axis rotation must be in this order.
        this.camera.rotateOnAxis(new Vector3(0, 1, 0), -Math.PI / 2);
        this.camera.rotateZ((rotation.z / 200) * -Math.PI);
        this.camera.rotateY((rotation.y / 200) * -Math.PI);
        this.camera.rotateX((rotation.x / 200) * -Math.PI);

        this.camera.update( renderer, this.mesh as unknown as Scene );

        texture.minFilter = currentMinFilter;

        return this;
    }

    update(dt: number, renderer: WebGLRenderer): boolean {
        if (this.material.uniforms.alpha.value < 1.0) {
            this.material.uniforms.alpha.value = Math.min(this.material.uniforms.alpha.value + 0.1, 1.0);
            this.material.needsUpdate = true;
            this.camera.update(renderer, this.mesh as unknown as Scene );
            return true;
        }

        return false;
    }
}

export { Skybox };
