import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
      <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL as string}
      clientId={process.env.REACT_APP_AUTHO_CLIENT_ID as string}
    authorizationParams={{
      audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      redirect_uri: window.location.origin
    }}
  >
        <App />
        </Auth0Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
